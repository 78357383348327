import React from 'react'

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import logo from './twc_logo.png';
import { rhythm } from '../utils/typography'

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(1),
        }}
      >
        <img
          src={logo}
          alt={`The Wellness Corner`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(2),
            height: rhythm(2),
          }}
        />
        <p style={{ fontSize: 13 }}>
          Your complete user manual for The Wellness Corner. <br/>
          For further queries, please <a style={{backgroundImage: 'none'}} href="mailto:support@truworthwellness.com">email us</a>. <br/>
          To visit The Wellness Corner, <a style={{backgroundImage: 'none'}} href="https://www.thewellnesscorner.com">click here.</a>
        </p>
      </div>
    )
  }
}

export default Bio
